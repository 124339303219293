import { ApolloQueryResult, QueryHookOptions, useQuery } from '@apollo/client'

import {
  GET_NETWORK_INFO,
  GET_ROLES,
  GetNetworkQuery,
  GetNetworkQueryVariables,
  GetRolesQuery,
} from 'tribe-api/graphql'
import { Network } from 'tribe-api/interfaces'

import { QueryResult } from '../../@types'

export type UseGetNetworkResult = QueryResult<GetNetworkQuery> & {
  network: Network
  refetch: (
    variables?: Partial<GetNetworkQueryVariables>,
  ) => Promise<ApolloQueryResult<GetNetworkQuery>>
}
const useGetNetwork = (
  variables: GetNetworkQueryVariables = {
    withDefaultSpaces: false,
    withRoles: true,
  },
  options?: QueryHookOptions<GetNetworkQuery, GetNetworkQueryVariables>,
): UseGetNetworkResult => {
  const { skip = false } = options || {}
  const { data, error, loading, client, refetch } = useQuery<
    GetNetworkQuery,
    GetNetworkQueryVariables
  >(GET_NETWORK_INFO, {
    variables,
    skip,
  })

  const network = data?.getNetwork as Network
  if (network?.roles && network.roles.length > 0) {
    client.writeQuery<GetRolesQuery>({
      query: GET_ROLES,
      data: { getRoles: network?.roles },
    })
  }

  return {
    data,
    network,
    error,
    loading,
    refetch,
    isInitialLoading: loading && data === undefined,
  }
}

export default useGetNetwork
